.sf-rfy {
  padding-bottom: 11.4375rem;

  @include media-breakpoint-up(md) {
    padding-bottom: 25.4375rem;
  }

  @include media-breakpoint-up(lg) {
    padding-top: 6.1875rem;
  }

  @include media-breakpoint-up(xl) {
    padding-top: 12.625rem;
    padding-bottom: 28.3125rem;
  }

  h2 {
    font-size: 1.875rem;
    line-height: 2.3125rem;
    margin-top: 3rem;

    @include media-breakpoint-up(md) {
      font-size: 3.375rem;
      line-height: 4rem;
      margin-top: 6.1875rem;
    }

    @include media-breakpoint-up(lg) {
      margin-top: 0;
    }
  }

  li {
    font-size: 1.125rem;
    line-height: 1.875rem;

    @include media-breakpoint-up(md) {
      font-size: 1.25rem;
      line-height: 2.125rem;
    }
  }

  &__list-dot {
    width: 3px;
    height: 3px;
    margin-top: 0.875rem;
  }

  h3 {
    font-size: 1.3125rem;
    margin-top: 2.75rem;

    @include media-breakpoint-up(md) {
      margin-top: 3.5rem;
    }

    @include media-breakpoint-up(xl) {
      margin-top: 3.75rem;
    }
  }

  &__list-cancel {
    margin-top: 0.5rem;
    width: 14px;
    height: 14px;
  }

  &__bottom-text {
    font-size: 8.8vw;
    line-height: 1;
    padding-top: 3.75rem;
    transform: translateY(6%);
    transition: .1s font-size;

    @include media-breakpoint-up(sm) {
      font-size: 10vw;
    }

    @include media-breakpoint-up(md) {
      padding-top: 6rem;
      transform: translateY(8%);
    }

    @media (min-width: 1200px) {
      transform: translateY(9%);
    }

    @include onIpadPortrait() {
      font-size: 5rem;
    }

    @include onIpadLandscape() {
      font-size: 6.875rem;
    }

    @include onIpadProLandscape() {
      font-size: 9.375rem;
    }

    @include media-breakpoint-up(xl) {
      padding-top: 6.625rem;
    }

    @include media-breakpoint-up(xxl) {
      font-size: 9.375rem;
    }
  }
}
