.sf-exercises-button {
  background-color: $primary-100;
  width: 37px;
  height: 37px;
  z-index: 1;
  transition: all .3s linear;

  &:hover {
    background-color: $tertiary;
  }

  @include media-breakpoint-up(md) {
    width: 51px;
    height: 51px;

    &__img {

      &--play {
        width: 40px;
      }

      &--lock {
        width: 18px;
      }
    }
  }

  @include media-breakpoint-up(lg) {
    width: 85px;
    height: 85px;

    &__img {

      &--play {
        width: 58px;
      }

      &--lock {
        width: 28px;
      }
    }
  }
}
