.sf-exercises-level-info {
  margin-bottom: 2.25rem;

  @include media-breakpoint-up(md) {
    margin-bottom: 3.0625rem;
  }

  @include media-breakpoint-up(lg) {
    margin-bottom: 0;
  }

  &:last-child {
    margin-bottom: 0;
  }

  &:nth-child(even) {

    @include media-breakpoint-up(md) {
      flex-basis: 60%;
    }

    @include media-breakpoint-up(lg) {
      flex-basis: auto;
    }
  }

  &:nth-child(odd) {

    @include media-breakpoint-up(md) {
      flex-basis: 40%;
    }

    @include media-breakpoint-up(lg) {
      flex-basis: auto;
    }
  }

  h4 {
    font-size: 1.125rem;

    @include media-breakpoint-up(md) {
      font-size: 1.25rem;
    }
  }

  span {
    @include media-breakpoint-up(lg) {
      font-size: 1.3125rem;
    }
  }
}
