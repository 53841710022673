.sf-exercises-messages {
  margin-top: 0.625rem;
  margin-bottom: 1.875rem;
}

.sf-exercises-message {
  flex-basis: 0;
  opacity: 0;
  transition: opacity .3s linear;

  &--visible {
    opacity: 1;
  }
}
