.sf-intro {
  z-index: 1;

  h1 {
    color: $primary;
    font-size: 2.25rem;
    line-height: 2.75rem;
    margin-bottom: 1.25rem;

    @include media-breakpoint-up(md) {
      font-size: 4.375rem;
      line-height: 5.375rem;
      margin-bottom: 1.5625rem;
    }
  }

  p {
    font-size: 1.125rem;

    @include media-breakpoint-up(md) {
      font-size: 1.25rem;
    }
  }
}
