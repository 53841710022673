.article {
  background-color: $white;
  padding: 1rem;

  .date {
    color: $gray-100;
    font-size: 0.875rem;
  }

  .title {
    width: 80%;
    margin-top: 0;
  }

  button {
    width: 32px;
    height: 32px;
    background-color: $primary-100;
    top: 0;
    right: 1rem;
    padding: 0;

    &:hover {
      background-color: $tertiary;
    }

    img {
      width: auto;
      height: 30px;
    }
  }

  .text-content {

    h2 {
      font-size: 2.6rem;
      margin-bottom: 1.5rem;
      line-height: 1.2;
    }

    h3 {
      font-size: 2.1rem;
      margin: 1.2rem 0;
    }

    a {
      font-weight: $font-weight-bold;
      position: relative;

      &::after {
        content: '';
        position: absolute;
        transition: background-color .3s ease-in;
        left: 0;
        bottom: -2px;
        height: 2px;
        border-radius: 2px;
        background-color: $primary-100;
        width: 100%;
      }

      &:hover {

        &::after {
          background-color: $tertiary;
        }
      }
    }
  }

  @include media-breakpoint-up(md) {
    padding: 1.8rem 1.5rem;

    .title {
      font-size: 1.75rem;
      line-height: 2.375rem;
    }

    button {
      width: 58px;
      height: 58px;

      img {
        height: 50px;
      }
    }
  }

  @include media-breakpoint-up(xl) {

    .title {
      font-size: 2.375rem;
      line-height: 3rem;
    }
  }
}
