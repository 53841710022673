.sf-hiw {

  &.sf-triangle-container {
    @include media-breakpoint-down(md) {
      margin-top: 0;
    }
  }

  &__title {
    font-size: 1.875rem;
    margin-top: 8.4375rem;

    @include media-breakpoint-up(md) {
      font-size: 3.375rem;
      margin-top: 13.0625rem;
    }

    @include media-breakpoint-up(lg) {
      margin-top: 0;
    }
  }

  &__text {
    font-size: 1.125rem;

    @include media-breakpoint-up(md) {
      font-size: 1.25rem;
    }
  }

  &__letter {
    font-size: 1.3125rem;
    width: 36px;
    height: 36px;

    @include media-breakpoint-up(md) {
      width: 48px;
      height: 48px;
    }
  }

  .animated-button {
    display: inline-flex;
    margin-top: 1.25rem;
    margin-bottom: 3.875rem;

    @include media-breakpoint-up(md) {
      margin-top: 1.625rem;
      margin-bottom: 6.0625rem;
    }

    @include media-breakpoint-up(lg) {
      margin-bottom: 3.875rem;
    }
  }
}
