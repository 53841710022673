.footer-menu {
  border-top: 1px solid $body-color;
  padding: 1.5rem 0 0;
  margin: 2.5rem 0 0;

  .btn-link,
  .list-item {
    font-weight: $font-weight-bold;
    font-size: 0.875rem;
    margin-right: 2rem;
  }

  .btn-link {
    padding: 0;
    margin-right: 0;
  }

  .copy {
    display: inline-block;
    font-size: 0.875rem;
  }

  @include media-breakpoint-up(md) {
    .list-item {
      display: inline-block;
    }
  }
}
