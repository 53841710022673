$font-size-mobile: 2.5rem;
$font-size-tablet: 5rem;
$font-size-desktop: 9.125rem;

.arrow-link {
  font-size: $font-size-mobile;
  font-weight: $font-weight-bold;
  line-height: 1.2;

  .arrow {
    display: inline-block;
    width: 50px;
    height: auto;
    aspect-ratio: 3 / 2;
    margin-left: 1rem;
    transition: transform .5s ease-out;
  }

  &:hover {
    .arrow {
      transform: translate3d(10%, 0, 0);
    }
  }

  @include media-breakpoint-up(md) {
    font-size: $font-size-tablet;

    .arrow {
      width: 110px;
      margin-left: 2rem;
    }
  }

  @include media-breakpoint-up(xl) {
    font-size: $font-size-desktop;

    .arrow {
      width: 180px;
      margin-left: 3rem;
    }
  }
}
