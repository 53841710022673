.animated-image {
  position: relative;
  overflow: hidden;

  img {
    transition: all 10s ease-in;
    transform-origin: center;
  }

  &:hover img {
    transform: scale(1.2);
  }

  &.sf-home-resources__img:hover {

    img {
      transform: scale(1.2) translate(-50%, -50%);
    }
  }
}
