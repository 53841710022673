.sf-home {

  &__title {
    color: $primary;
    font-size: 2.25rem;

    @include media-breakpoint-up(md) {
      font-size: 4.375rem
    }
  }

  &__intro-container {
    order: 1;
    z-index: $zindex-dropdown;

    @include media-breakpoint-up(lg) {
      order: 0;
      z-index: 0;
    }
  }

  &__intro {
    font-size: 1.125rem;

    @include media-breakpoint-up(md) {
      font-size: 1.25rem;
    }
  }

  &__video-container {
    order: 0;
    z-index: 0;

    @include media-breakpoint-up(lg) {
      order: 1;
      z-index: $zindex-dropdown;
    }

    &::before {
      content: "";
      display: block;
      width: 100%;
      padding-top: calc((9/16) * 100%);
    }
  }

  &__video {
    padding: inherit;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
}
