.footer-logos {
  margin: 4rem 0 0;

  .logo {
    width: auto;
    margin: 2rem 1.5rem 0 0;
    max-width: 100%;

    &.small {
      max-width: 40%;

      @include media-breakpoint-down(md) {
        max-width: 100%;
      }
    }
  }

  @include media-breakpoint-up(md) {
    margin: 2rem 0;

    .logo {
      &.small {
          max-width: 100%;
      }
    }
  }

  @include media-breakpoint-down(md) {
    .logo {
      height: 50px;
    }

    .logo-small-height {
      height: 15px;
    }
  }
}
