.articles {
  margin-bottom: 4rem;

  .see-more {
    margin-top: 4rem;
  }

  @include media-breakpoint-up(md) {
    margin-bottom: 5rem;

    .see-more {
      margin-top: 5rem;
    }
  }
}
