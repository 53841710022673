@mixin font-montserrat-bold () {
  font-family: $font-family-base;
  font-weight: $font-weight-bold;
}

@mixin onIpadPortrait () {
  @media all and (width: 768px) {
    @content;
  }
}

@mixin onIpadLandscape () {
  @media all and (width: 1024px) {
    @content;
  }
}

@mixin onIpadProLandscape () {
  @media all and (width: 1366px) {
    @content;
  }
}
