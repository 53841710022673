.sf-testimonials {
  background-color: $quaternary-100;
  margin-top: 3.9375rem;
  padding-top: 2.8125rem;
  padding-bottom: 4.375rem;

  @include media-breakpoint-up(md) {
    margin-top: 6.5rem;
    padding-top: 5.375rem;
    padding-bottom: 8.5rem;
  }

  @include media-breakpoint-up(xl) {
    margin-top: 11.125rem;
    padding-top: 7.625rem;
    padding-bottom: 7.875rem;
  }

  h2 {
    font-size: 1.875rem;
    margin-bottom: 1.875rem;

    @include media-breakpoint-up(md) {
      font-size: 3.375rem;
      margin-bottom: 2.3125rem;
    }

    @include media-breakpoint-up(xl) {
      margin-bottom: 2.625rem;
    }
  }
}

.sf-testimonial {
  padding: 2.375rem 2.375rem 2.9375rem;
  height: 100%;

  p {
    font-size: 1.125rem;
    line-height: 1.875rem;

    @include media-breakpoint-up(xl) {
      font-size: 1.25rem;
      line-height: 2.125rem;
    }
  }

  span {
    line-height: 1.5rem;
  }

  .sf-testimonial__img {
    width: 100%;
    max-width: 155px;
    height: auto;
    border-radius: 50%;
    aspect-ratio: 1 / 1;
    object-fit: cover;
  }

  .sf-testimonial__quote-icon {
    width: 34px;
    height: 25px;
  }
}
