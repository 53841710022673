.sf-exercises-video-wrapper {
  height: 200px;

  @include media-breakpoint-up(md) {
    height: 416px;
  }

  @include media-breakpoint-up(lg) {
    height: 553px;
  }

  @include media-breakpoint-up(xl) {
    height: 691px;
  }
}

.sf-exercises-video-player > div > div {
  //overwrite inline player style
  position: initial !important;
}

.sf-exercises-video-logo {
  width: 85px;
  top: 8px;
  right: 26px;
  transition: all .3s linear;

  &--hidden {
    top: -49px;
  }

  @include media-breakpoint-up(md) {
    width: 176px;
    top: 20px;
    right: 35px;

    &--hidden {
      top: -79px;
    }
  }

  @include media-breakpoint-up(lg) {
    width: 267px;
    top: 34px;
    right: 47px;

    &--hidden {
      top: -111px;
    }
  }
}

.sf-exercises-video-title {
  bottom: 20px;
  left: 31px;
  transition: all .3s linear;

  &--hidden {
    bottom: -41px;
  }

  @include media-breakpoint-up(md) {
    bottom: 35px;
    left: 33px;

    &--hidden {
      bottom: -65px;
    }
  }

  @include media-breakpoint-up(lg) {
    left: 47px;

    &--hidden {
      bottom: -127px;
    }
  }

  h3 {
    font-size: 2.5rem;
    line-height: 3.0625rem;

    @include media-breakpoint-up(md) {
      font-size: 5rem;
    }

    @include media-breakpoint-up(lg) {
      font-size: 9.375rem;
    }
  }

  img {
    width: 36px;
    margin-left: 1.0625rem;

    @include media-breakpoint-up(md) {
      width: 97px;
      margin-left: 2.125rem;
    }

    @include media-breakpoint-up(lg) {
      width: 182px;
      margin-left: 3.125rem;
    }
  }
}
