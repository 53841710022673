.sf-faq-container > div {
  padding: 3.4375rem 0.9375rem 4.125rem;

  @include media-breakpoint-up(md) {
    padding: 3.4375rem 2.6875rem 6.625rem;
  }

  @include media-breakpoint-up(lg) {
    padding: 3.4375rem 7.6875rem 6.625rem 2.6875rem;
  }

  .sf-faq {
    margin-top: 2.4375rem;

    &:first-child {
      margin-top: 0;
    }
  }
}

.sf-faq {

  &__big-letter {
    font-size: 1.5625rem;
    width: 50px;
    height: 50px;

    @include media-breakpoint-up(md) {
      font-size: 3.125rem;
      width: 114px;
      height: 114px;
    }

    @include media-breakpoint-up(lg) {
      font-size: 3.75rem;
      width: 134px;
      height: 134px;
    }

    @include media-breakpoint-up(xl) {
      font-size: 4.375rem;
      width: 154px;
      height: 154px;
    }
  }

  &__small-letter {
    font-size: 1.3125rem;
    width: 36px;
    height: 36px;
  }

  &__body {
    font-size: 1rem;
    line-height: 1.875rem;

    @include media-breakpoint-up(md) {
      font-size: 1.125rem;
    }
  }
}
