.animated-link,
.animated-link-text {
  font-weight: $font-weight-bold;
  display: inline-block;
  position: relative;

  &::after {
    content: '';
    position: absolute;
    width: 0;
    transition: width .3s ease-in;
    left: 0;
    bottom: -6px;
    height: 4px;
    border-radius: 2px;
    background-color: $primary-100;
  }

  &--active::after,
  &:hover::after {
    width: 100%;
  }
}

.animated-link-text {

  &--alt {
    background-color: $white;
  }

  &::after {
    width: 100%;
  }
}
