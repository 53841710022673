/* Brand Colors */

$primary: #00A6A0;
$primary-100: #56CDC9;
$primary-200: #A7E0DE;
$primary-300: #CAECEB;

$secondary: #1E5069;
$secondary-100: #8EA7B4;
$secondary-200: #BBCAD2;

$tertiary: #FFDC54;
$tertiary-100: #FFEDA9;
$tertiary-200: #FFF4CB;

$quaternary: #F5504B;
$quaternary-100: #FAA7A5;
$quaternary-200: #FCCAC9;

/* Grays */
$gray: #02020A;
$gray-100: #67676C;
$gray-200: #B3B3B5;
$gray-300: #FCFCFC;

$colors: (
  "primary": $primary-100,
  "secondary": $secondary,
  "tertiary": $tertiary,
  "gray": $gray,
  "gray-300": $gray-300,
);

$theme-colors: (
  "primary": $primary-100,
  "primary-dark": $primary,
  "secondary": $secondary,
  "tertiary": $tertiary,
  "quaternary": $quaternary,
  "quaternary-100": $quaternary-100,
  "gray": $gray,
  "gray-300": $gray-300,
);

/* Buttons */

$btn-padding-y: .8rem;
$btn-padding-x: 1.5rem;
$border-radius: 2rem;
$border-radius-lg: $border-radius;
$border-radius-sm: $border-radius;
$btn-font-weight: 700;

/* Fonts */

$body-color: $gray;
$font-weight-normal: 500;
$font-family-sans-serif: 'Montserrat', -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";

$link-color: $gray;
$link-hover-decoration: none;

/* Grid Breakpoints */

$grid-breakpoints: (
  xs: 0,
  sm: 330px,
  md: 768px,
  lg: 992px,
  xl: 1440px,
  xxl: 1600px
);

/* Container widths */

$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1440px,
  xxl: 1600px,
);

$spacer: 1rem;

$spacers: (
  6: ($spacer * 4),
);
