.sf-exercises-modal {
  width: 100%;
  padding: 3.1875rem 2.4375rem;
  bottom: 0;
  left: -100%;
  z-index: $zindex-popover;
  box-shadow: 0px 5px 15px 10px rgba($black, 0);
  transition: all .3s linear;

  &--visible {
    left: 0;
    box-shadow: 0px 5px 15px 10px rgba($black, 0.25);
  }

  @include media-breakpoint-up(md) {
    width: 621px;
    padding: 3.375rem 4.125rem 5rem;

    &--visible {
      box-shadow: 0px 5px 15px 10px rgba($black, 0.15);
    }
  }

  h3 {
    margin-bottom: 1.375rem;

    @include media-breakpoint-up(md) {
      padding-right: 1.5625rem;
      margin-bottom: 1.625rem;
    }
  }

  p {
    font-size: 1.125rem;
    line-height: 1.875rem;
    margin-bottom: 2.25rem;

    @include media-breakpoint-up(md) {
      font-size: 1.25rem;
      line-height: 2.125rem;
      margin-bottom: 3.375rem;
    }
  }
}
