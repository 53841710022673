.sf-menu {
  background-color: $white;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: -100vw;
  z-index: $zindex-popover;
  display: none;
  transition: left 400ms ease-in;

  @include media-breakpoint-up(lg) {
    position: initial;
    height: auto;
  }

  &--visible {
    left: 0;
    display: block;
  }

  &__triangle {
    bottom: 0;

    &::before {
      background-color: $tertiary;
      content: '';
      border-radius: 1rem;
      transform: rotate(45deg) translate3d(-50%, 50%, 0);
      transition: width .2s ease-in, height .2s ease-in;
      position: absolute;
      width: 470px;
      height: 470px;
      bottom: 0;
      left: 0;
      z-index: -1;

      @include media-breakpoint-up(sm) {
        width: 700px;
        height: 700px;
      }

      @include media-breakpoint-up(md) {
        width: 860px;
        height: 860px;
      }
    }
  }

  &__list {
    font-size: 1.375rem;
    font-weight: $font-weight-bold;
    margin-top: 7.6875rem;

    @include media-breakpoint-up(md) {
      font-size: 2.375rem;
      margin-top: 15rem;
    }

    @include media-breakpoint-up(lg) {
      font-size: 1rem;
      margin-top: 0;
    }

    &__item {
      margin: 1.5rem 0 0;

      &:first-child {
        margin: 0;
      }

      @include media-breakpoint-up(lg) {
        margin: 0 0 0 1.125rem;
      }

      @include media-breakpoint-up(xl) {
        margin: 0 0 0 4.25rem;
      }
    }
  }

  .animated-button {
    @include media-breakpoint-down(lg) {
      padding: 0.625rem;
    }

    &.sf-donation {
      font-size: 1rem;
      padding: 0.75rem 1.25rem;
    }
  }
}
