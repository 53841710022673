@import 'core';
@import 'bootstrap';

/* Custom styles */
@import 'components/header/header';
@import 'components/header/header-menu';
@import 'components/header/header-hamburger';
@import 'components/title';

@import 'components/footer/footer';
@import 'components/footer/footer-menu';
@import 'components/footer/footer-logos';

@import 'components/home/home';
@import 'components/home/how-it-works';
@import 'components/home/exercise-types';
@import 'components/home/right-for-you';
@import 'components/home/helpful-resources';
@import 'components/home/testimonials';

@import 'components/exercises/exercises';
@import 'components/exercises/level';
@import 'components/exercises/level-header';
@import 'components/exercises/video';
@import 'components/exercises/buttons';
@import 'components/exercises/progress-bar';
@import 'components/exercises/message';
@import 'components/exercises/level-info';
@import 'components/exercises/sublevel';
@import 'components/exercises/modal';

@import 'components/resources/articles';
@import 'components/resources/article';

@import 'components/recommendations/index';

@import 'components/about/index';

@import 'components/faq';

@import 'components/contact';

@import 'components/typography';
@import 'components/animated-link';
@import 'components/arrow-link';
@import 'components/animated-button';
@import 'components/back-to-top';
@import 'components/intro';
@import 'components/helper';
@import 'components/triangle-container';
@import 'components/animated-image';
@import 'components/form';

body,
html,
#root {
  min-height: 100%;
}

html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  font-family: $font-family-sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: $font-family-monospace;
}
