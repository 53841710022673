.sf-scroll-to-top {
  width: 58px;
  height: 58px;
  font-size: 1.8125rem;
  bottom: 0;
  right: 25px;
  z-index: $zindex-modal;
  opacity: 0;
  transition: all .3s ease-in;
  pointer-events: none;
  background-color: $primary-100;

  &--visible {
    opacity: 1;
    bottom: 25px;
    pointer-events: inherit;
  }

  &:hover {
    background-color: $tertiary;
  }

  img {
    width: 24px;
    height: 27px;
  }
}
