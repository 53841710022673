.sf-about {
  .separator {
    height: 4px;
  }

  @include media-breakpoint-up(lg){
    .separator {
      height: 8px;
    }
  }

  @include media-breakpoint-up(xl){
    .sf-intro .left {
      padding-bottom: 10rem;
    }
  }
}
