h1 {
  font-size: 2.25rem;
  line-height: 2.8125rem;

  @include media-breakpoint-up(md) {
    font-size: 4.375rem;
    line-height: 5.375rem;
  }
}

h2 {
  font-size: 1.875rem;
  line-height: 2.375rem;
  font-weight: $font-weight-bold;

  @include media-breakpoint-up(md) {
    font-size: 3.375rem;
    line-height: 4rem;
  }
}

h3 {
  font-size: 1.375rem;
  line-height: 1.75rem;
  font-weight: $font-weight-bold;

  @include media-breakpoint-up(md) {
    font-size: 2.375rem;
    line-height: 3rem;
  }
}

h4 {
  font-size: 1.125rem;
  line-height: 1.4375rem;
  font-weight: $font-weight-bold;

  @include media-breakpoint-up(md) {
    font-size: 1.75rem;
    line-height: 2.375rem;
  }
}

h5 {
  line-height: 1.25rem;
  font-weight: $font-weight-bold;

  @include media-breakpoint-up(md) {
    font-size: 1.3125rem;
    line-height: 1.9375rem;
  }
}
