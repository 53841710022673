.sf-form {
  &__label {
    font-size: 0.9375rem;
    line-height: 1.1875rem;
    font-weight: bold;

    @include media-breakpoint-up(lg) {
      font-size: 1.125rem;
      line-height: 1.875rem;
    }
  }

  &__field {
    border-radius: 3px;
    padding: 1rem 3.125rem 1rem 1.25rem;
    font-size: 0.875rem;
    line-height: 1.5rem;
    border: none;
    height: auto;
    color: $gray;
    border: 3px solid transparent;

    &:hover {
      border-color: $tertiary;
      cursor: pointer;
    }

    &:focus {
      box-shadow: none;
      border-color: transparent;
    }

    &::placeholder {
      font-size: 0.875rem;
      line-height: 1.5rem;
      color: $gray-100;
    }

    &-error,
    &-error:focus {
      border-color: $quaternary;
    }
  }

  &__radio-label {
    display: block;
    position: relative;
    padding-left: 2rem;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    padding-right: 3.125rem;

    @include media-breakpoint-up(lg) {
      padding-right: 0;
    }

    input {
      display: none;
    }

    &__icon {
      position: absolute;
      top: 0;
      left: 0;
      height: 20px;
      width: 20px;
      background-color: #ffffff;
      border-radius: 50%;
      
      /* Create the indicator (the dot/circle - hidden when not checked) */
      &::after {
        content: "";
        inset: 0;
        margin: auto;
        width: 8px;
        height: 8px;
        border-radius: 50%;
        background-color: transparent;
        position: absolute;
      }
    }
    
    /* When the radio button is checked, add yellow background */
    & input:checked ~ &__icon {
      background-color: $tertiary;

      &::after {
        background-color: $gray;
      }
    }
  }
  
  &__error-icon {
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto;
    right: 1.25rem;
  }

  &__message {
    font-size: 0.8125rem;
    line-height: 1.25rem;

    @include media-breakpoint-up(lg) {
      font-size: 0.9375rem;
      line-height: 1.5rem;
    }
  }
}