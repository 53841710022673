.sf-exercises {
  padding-top: 0;
  padding-bottom: 2.3125rem;

  @include media-breakpoint-up(md) {
    padding-bottom: 5.9375rem
  }

  @include media-breakpoint-up(lg) {
    padding-top: 0;
  }

  @include media-breakpoint-up(xl) {
    padding-top: 1.8125rem;
    padding-bottom: 11.125rem;
  }

  h3 {
    font-size: 1.375rem;

    @include media-breakpoint-up(md) {
      font-size: 2.375rem;
    }
  }

  &__text {
    font-size: 1.125rem;
    line-height: 1.875rem;

    @include media-breakpoint-up(md) {
      font-size: 1.25rem;
      line-height: 2.125rem;
    }
  }

  .exercise-card {
    background-color: $white;
    padding: 1.125rem 1.75rem 2.75rem 2.75rem;

    @include media-breakpoint-up(md) {
      height: 100%;
    }

    &--alt {
      background-color: $secondary;

      p {
        color: $white;
        padding-top: 1rem;

        @include media-breakpoint-up(md) {
          padding-top: 11.75rem;
        }
      }
    }

    &__number,
    &__sign {
      width: 36px;
      height: 36px;
    }

    &__number,
    h3 {
      font-size: 1.3125rem;
    }

    h3 {
      margin-top: 21px;

      @include media-breakpoint-up(md) {
        line-height: 1.75rem;
        margin-top: 0;
      }
    }

    img {
      @include media-breakpoint-up(md) {
        height: 135px;
      }
    }

    p {
      font-size: 1.125rem;
      line-height: 1.875rem;
    }
  }
}
