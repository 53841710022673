.animated-button {
  border-radius: 9999px;
  color: $gray;
  font-size: 1rem;
  font-weight: $font-weight-bold;
  display: inline-block;
  position: relative;
  padding: 1.125rem;
  overflow: hidden;

  @include media-breakpoint-up(xl) {
    padding: 1.125rem 1.875rem;
  }

  &::before,
  &::after {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    right: 0;
  }

  &::before {
    background-color: $tertiary;
  }

  &--alt::before {
    background-color: $primary-100;
  }

  &::after {
    background-color: $primary-100;
    transition: width .3s ease-in;
  }

  &--alt::after {
    background-color: $white;
  }

  span {
    z-index: $zindex-dropdown;
  }

  &:hover::after,
  &--active::after {
    width: 0;
  }

  &.no-animate {
    &:hover::after,
    &--active::after {
      width: 100%;
    }
  }
}
