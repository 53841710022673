.sf-exercises-level-header {
  height: 55px;
  padding-left: 2.6875rem;
  margin-bottom: 1.375rem;

  &::before {
    border-radius: 2px;
    content: '';
    position: absolute;
    width: 39px;
    height: 39px;
    left: -19px;
    transform: rotate(45deg);
  }

  &--primary::before {
    background-color: $primary;
  }

  &--secondary::before {
    background-color: $secondary;
  }

  &--tertiary::before {
    background-color: $tertiary;
  }

  &--quaternary::before {
    background-color: $quaternary;
  }

  @include media-breakpoint-up(md) {
    margin-bottom: 2.5625rem;
  }

  @include media-breakpoint-up(lg) {
    margin-bottom: 2.4375rem;
  }

  h2 {
    font-size: 1.875rem;

    @include media-breakpoint-up(md) {
      font-size: 3.375rem;
    }
  }
}
