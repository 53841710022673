.sf-title {
  &.h2 {
    font-size: 1.875rem;
    margin-top: 8.4375rem;

    @include media-breakpoint-up(md) {
      font-size: 3.375rem;
      margin-top: 13.0625rem;
    }

    @include media-breakpoint-up(lg) {
      margin-top: 0;
    }
  }

  &.h3 {
    font-size: 1.375rem;

    @include media-breakpoint-up(md) {
      font-size: 2.375rem;
    }
  }

  &.h6 {
    font-size: 1rem;
  }
}
