.sf-hamburger {
  top: 0;
  right: 0;
  z-index: $zindex-tooltip;

  @include media-breakpoint-up(md) {
    top: 1.6875rem;
    right: 1rem;
  }

  &__container {
    // Important is used here because this rules
    // are set inline by a third-library
    width: 70.88px !important;
    height: 60px !important;

    @include media-breakpoint-up(md) {
      width: 97px !important;
      height: 82px !important;
    }
  }
}
