.sf-contact {
  width: 100%;
  margin-top: 8.4375rem;

  @include media-breakpoint-up(md) {
    margin-top: 11rem;
  }

  @include media-breakpoint-up(lg) {
    width: 82%;
    margin: 0 auto;
  }

  @include media-breakpoint-up(xl) {
    width: 1180px;
  }

  &__title {
    font-size: 1.375rem;

    @include media-breakpoint-up(md) {
      font-size: 2.375rem;
    }
  }

  &__note {
    font-size: 0.876rem;
    line-height: 1.5rem;

    @include media-breakpoint-up(lg) {
      font-size: 0.9375rem;
      text-align: right;
      align-self: flex-end;
    }
  }

  &__submit-btn.animated-button {
    width: 100%;
    text-align: center;
    padding: 1.25rem 2.5rem;
    font-size: 1rem;
    line-height: 1.2rem;
    color: $gray;
    display: block;

    &::before {
      background-color: $tertiary;
    }

    @include media-breakpoint-up(lg) {
      font-size: 1.375rem;
      line-height: 2rem;
    }
  }
}
