.sf-header {
  height: 63px;
  margin-bottom: 2.75rem;

  @include media-breakpoint-up(md) {
    height: 140px;
  }

  @include media-breakpoint-up(lg) {
    height: auto;
    padding-right: 1rem;
    padding-left: 1rem;
  }

  @include media-breakpoint-up(xl) {
    margin-bottom: 4.3125rem;
  }

  @include media-breakpoint-up(xxl) {
    padding-right: 2.5625rem;
    padding-left: 2.5625rem;
  }
}

.sf-translation,
.sf-contact-btn {
  color: $gray;
  font-size: 1.375rem;
  font-weight: $font-weight-bold;

  @include media-breakpoint-up(md) {
    font-size: 2.375rem;
  }

  @include media-breakpoint-up(lg) {
    font-size: 0.8125rem;
  }
}

.sf-logo {
  position: absolute;
  top: 0.8125rem;
  left: 1rem;
  width: 142px;
  z-index: $zindex-tooltip;

  @include media-breakpoint-up(md) {
    width: 264px;
    top: 2.125rem;
  }

  @include media-breakpoint-up(lg) {
    position: initial;
    width: 200px;
  }

  @include media-breakpoint-up(xl) {
    width: 264px;
  }
}

.sf-donation.animated-button {
  @include media-breakpoint-up(lg) {
    padding: 0.25rem 1rem;
    font-size: 0.75rem;

    &::before {
      background-color: $primary-100;
    }

    &::after {
      background-color: $tertiary;
    }
  }
}
