.sf-exercises-level {
  margin-bottom: 4.25rem;

  @include media-breakpoint-up(md) {
    margin-bottom: 6rem;
  }

  @include media-breakpoint-up(lg) {
    margin-bottom: 8.9375rem;
  }

  &:last-child {
    margin-bottom: 0;
  }
}
