.sf-home-resources {
  margin-top: -5.9375rem;

  @include media-breakpoint-up(sm) {
    margin-top: -7.0625rem;
  }

  @include media-breakpoint-up(md) {
    margin-top: -16.875rem;
  }

  &__img {
    .image {
      position: relative;
      overflow: hidden;
      padding-top: 66.67%;
    }

    img {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      height: 100%;
      width: 100%;
    }

    @include media-breakpoint-up(md) {
      height: 480px;
    }

    @include media-breakpoint-up(lg) {
      height: 590px;
      overflow: hidden;

      .image {
        height: 100%;
      }

      img {
        width: auto;
        height: 100%;
      }
    }
  }

  &__info {
    padding: 39px 20px 53px;

    @include media-breakpoint-up(md) {
      padding: 4.1875rem 2.5625rem 4.75rem;
    }

    @include media-breakpoint-up(lg) {
      padding: 0 4.875rem;
    }

    @include media-breakpoint-up(xl) {
      padding: 0 6.875rem;
    }
  }

  h2 {
    font-size: 1.375rem;

    @include media-breakpoint-up(md) {
      font-size: 2.375rem;
    }
  }

  p {
    font-size: 1.125rem;
    line-height: 1.875rem;

    @include media-breakpoint-up(md) {
      font-size: 1.25rem;
    }
  }

  a {
    @include media-breakpoint-up(xl) {
      align-self: flex-start;
    }
  }
}
