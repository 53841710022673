.footer {
  &.triangle {
    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 460px;
      height: 460px;
      background-color: $tertiary;
      border-radius: 1.5rem;
      transform: rotate(45deg) translate3d(-50%, 50%, 0);
      transition: width .2s, height .2s;
      z-index: -1;
    }
  }

  .arrow-link.big {
    margin: 3rem 0 5rem;
    display: block;
  }

  @include media-breakpoint-up(md) {
    &.triangle {
      &::before {
        width: 770px;
        height: 770px;
      }
    }

    .arrow-link.big {
      margin: 5rem 0 7rem;
    }
  }

  @include media-breakpoint-up(lg) {
    &.triangle {
      &::before {
        width: 980px;
        height: 980px;
      }
    }

    .arrow-link.big {
      margin: 7rem 0 10rem;
    }
  }
}
