.sf-exercises-levels {
  margin-top: 2.875rem;

  @include media-breakpoint-up(md) {
    margin-top: 3.125rem;
  }

  @include media-breakpoint-up(lg) {
    margin-top: 5.9375rem;
  }
}
