.sf-exercises-progress {
  width: 100%;
  height: 20px;

  @include media-breakpoint-up(lg) {
    margin-top: 2.1875rem;
  }

  &__steps {
    padding: 0 0.0625rem;
    background-clip: content-box;
    transition: all .3s linear;

    &:first-child {
      border-radius: 16px 0 0 16px;
      padding-left: 0;
    }

    &:last-child {
      border-radius: 0 16px 16px 0;
      padding-right: 0;
    }

    &--primary {
      background-color: $primary-300;
    }

    &--secondary {
      background-color: $secondary-200;
    }

    &--tertiary {
      background-color: $tertiary-200;
    }

    &--quaternary {
      background-color: $quaternary-200;
    }
  }
}
