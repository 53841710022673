.sf-exercises-sublevels {
  margin-top: 1rem;
  margin-bottom: 1.875rem;
  cursor: pointer;

  @include media-breakpoint-up(md) {
    margin-top: 1rem;
    margin-bottom: 1.125rem;
  }

  @include media-breakpoint-up(xl) {
    margin: 0;
  }
}

.sf-exercises-sublevel {
  background-size: cover;
  background-clip: content-box;
  background-position: center center;
  flex-basis: 50%;
  height: 95px;
  margin-bottom: 0.9375rem;

  &:nth-child(odd) {
    padding-right: 0.46875rem;
  }

  &:nth-child(even) {
    padding-left: 0.46875rem;
  }

  &--selected {

    & .sf-exercises-sublevel__wrapper::before {
      content: '';
      border: 3px solid $black;
      width: 100%;
      height: 100%;
      position: relative;
      z-index: 1;

      @include media-breakpoint-up(md) {
        border-width: 5px;
      }
    }
  }

  @include media-breakpoint-up(md) {
    flex-basis: 20%;
    height: 79px;
    margin-bottom: 0;
    padding-right: 0.375rem;
    padding-left: 0.375rem;

    &:nth-child(odd),
    &:nth-child(even) {
      padding-right: 0.375rem;
      padding-left: 0.375rem;
    }

    &:first-child {
      padding-left: 0;
    }

    &:last-child {
      padding-right: 0;
    }
  }

  @include media-breakpoint-up(lg) {
    height: 99px;
  }

  @include media-breakpoint-up(xl) {
    flex-basis: auto;
    height: 20%;
    width: 100%;
    padding-top: 0.46875rem;
    padding-bottom: 0.46875rem;

    &:nth-child(odd),
    &:nth-child(even) {
      padding-right: 0;
      padding-left: 0;
    }

    &:first-child {
      padding-top: 0;
    }

    &:last-child {
      padding-bottom: 0;
    }
  }

  &__wrapper:hover {

    .sf-exercises-sublevel__image {
      transform: scale(1.2);
    }
  }

  &__image {
    position: absolute;
    top: 0;
    left: 0;
    transition: all 2s ease-in;
    transform-origin: center;
  }

  &__icon {
    padding: inherit;
    top: 8px;
    right: 9px;

    &--check {
      width: 24px;
    }
  }

  &__title {
    font-size: 1.3125rem;
    margin-bottom: 0.75rem;
    margin-left: 1.0625rem;

    @include media-breakpoint-up(md) {
      font-size: 1rem;
    }

    @include media-breakpoint-up(lg) {
      font-size: 1.3125rem;
    }
  }
}
