.sf-triangle-container {
  margin-top: -5.0625rem;
  padding-bottom: 4.25rem;

  @include media-breakpoint-up(md) {
    margin-top: -8.125rem;
    padding-bottom: 6.75rem;
  }

  @include media-breakpoint-up(lg) {
    margin-top: 9.25rem;
    padding-top: 11.1875rem;
    padding-bottom: 11.375rem;

    &.sf-hiw {
      padding-bottom: 0;
    }
  }

  @media (min-width: 1205px) and (max-width: 1439px) {

    &.sf-hiw {
      padding-bottom: 3.125rem;
    }
  }

  @include media-breakpoint-up(xl) {

    &.under {
      transform: translateY(-100px);
      margin: 0 0 -100px;
    }
  }

  &::before {
    content: '';
    border-radius: 1rem;
    transform: rotate(45deg) translate3d(-50%, 50%, 0);
    transition: width .2s ease-in, height .2s ease-in;
    position: absolute;
    width: 600px;
    height: 600px;
    top: -29px;
    left: 0;
    z-index: 0;

    @include media-breakpoint-up(sm) {
      width: 700px;
      height: 700px;
    }

    @include media-breakpoint-up(md) {
      width: 1030px;
      height: 1030px;
      top: -150px;
    }

    @include media-breakpoint-up(lg) {
      width: 1030px;
      height: 1030px;
      top: -100px;
    }
  }

  &--primary-200 {
    &::before {
      background-color: $primary-200;
    }
  }

  &--tertiary-100 {
    &::before {
      background-color: $tertiary-100;
    }
  }

  &--quaternary {
    &::before {
      background-color: $quaternary;
    }
  }

  .sf-container {
    position: relative;
  }
}
