.sf-recommendations {
  @media all and (max-width: 400px){
    .sf-intro h2 {
      font-size: 9vw;
      line-height: 1.3;
    }
  }

  @include media-breakpoint-up(lg){
    .sf-intro h2 {
      font-size: 4.5vw;
      line-height: 1.3;
    }
  }

  @include media-breakpoint-up(xl){
    .sf-intro .left {
      padding-bottom: 10rem;
    }
  }

  @include media-breakpoint-up(xxl){
    .sf-intro h2 {
      font-size: 4.375rem;
    }
  }
}
